<template>
 <div class="bigDIv" >
  <div class="qrcodeImg" @click="bodyClick">
<img src="https://oss.hshwhkj.com/images/360/360A/3601.jpg" alt="" @click="bodyClick">
<img :src="qrCode" alt="" class="qrcodes" @click="bodyClick">
<div class="qrTime" @click="bodyClick">
  <van-count-down :time="time">
  <template #default="timeData">
    <span class="block">0{{ timeData.minutes }}</span>
    <span class="colon">:</span>
    <span class="block">{{ timeData.seconds }}</span>
  </template>
</van-count-down>
</div>
  </div>
<img src="https://oss.hshwhkj.com/images/360/360A/3.jpg" alt="" @click="bodyClick">
<img src="https://oss.hshwhkj.com/images/360/360A/3603.jpg" alt="" class="last" @click="bodyClick">
<div class="btn" v-if="!qrCodeDir">
<img src="https://oss.hshwhkj.com/images/360/360A/360btn.jpg" alt=""  @click="bodyClick">
<img :src="qrCode" alt="" class="btnqrcodes" @click="bodyClick" v-show="btoQrcode">
<img src="https://oss.hshwhkj.com/images/360/360A/sjx.png" alt="" class="sjx" @click="bodyClick" v-show="btoQrcode">
</div>

      <!-- 问题弹框 -->
        <el-dialog
            :visible.sync="dialogFormVisible"
            :lock-scroll="true"
            :close-on-click-modal="false"
            custom-class="dirclass"
            width="50%"
        >
            <div v-if="flages" class="dirsA">
                <div style="" class="textTitle">
                    1.请问您对《易经》的了解程度如何? <span style="color: red">*</span>
                </div>
                <div style="margin-top: 20px">
                    <div
                        v-for="(text, index) in texts"
                        :key="index"
                        @click="handleClick(index)"
                        :class="{active: index === selected}"
                        class="texts"
                    >
                        {{ text }}
                    </div>
                </div>
            </div>
            <div v-else class="dirsA">
                <div class="textTitle">2.请问您希望学习《易经》中的哪些知识? <span style="color: red">*</span></div>
                <div style="margin-top: 20px">
                    <div
                        v-for="(text, index) in textsA"
                        :key="index"
                        @click="throttleSubmitA(index)"
                        :class="{active: index === selectedA}"
                        class="texts"
                    >
                        {{ text }}
                    </div>
                </div>
            </div>
        </el-dialog>
          <!-- 二维码弹框 -->
        <el-dialog
            :visible.sync="qrCodeDir"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            :destroy-on-close="true"
                  width="35%"

        >
  <div class="codes">
          <div class="qrCodeCss">
            <img :src="qrCode "  class="imgsas"/>
 

        </div>
  </div>
        </el-dialog>
        <div class="model"></div>
 </div>
</template>

<script>
import {getWxCode} from "../../api/360AD.js";
 export default {

  data () {
   return {
    btoQrcode:false,
    lastClickTime:0,
            flages: true,
 selected: null,
            selectedA: null,
            qrCodeDir:false,
     time:   10 * 60 * 1000,
       dialogFormVisible: false,
         texts: ["略有一些了解", "有一些自己见解", "不是很了解"],
            textsA: ["面相识人学", "旺运姓名学", "家居风水学", "八字命理学"],
        ruleForm: {
            
                land_link: "", //落地页链接
      
            },
            qrCode:''
   }
  },
  created(){
        this.ruleForm.land_link = window.location.href;
        this.wxClick()

  },
  mounted() {
window.addEventListener('scroll', this.handleScroll);
 
},
  methods: {
     handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      
      // 当页面滚动到达600像素时，显示盒子
      if (scrollTop >= 600) {
        this.btoQrcode = true;
      } else {
        this.btoQrcode = false;
      }
    },
    bodyClick(){
      this.dialogFormVisible = true;
    },
        handleClick(index) {
            this.selected = index;
            this.flages = false;
            
        },
            throttleSubmitA(index) {
            const currentTime = Date.now();
            if (currentTime - this.lastClickTime < 600) {
                return; // 在一秒内多次点击，直接返回
            }
            this.lastClickTime = currentTime; // 更新上次点击时间

            this.selectedA = index;
            this.flages = true;

                   this.dialogFormVisible = false;
            this.qrCodeDir = true;
            // this.wxClick(); // 调用实际的支付方法
        },
       async wxClick() {
        const {data} =  await  getWxCode(this.ruleForm)
        this.qrCode=data.data.qr_code
        
            // if (this.selected === null || this.selectedA === null) {
            //     this.$message({type: "error", message: "请选择选项"});
            // } else {
            //     const {data} = await getWxCsad(this.ruleForm);
            //     window.location.href = data.data;
            //     this.dialogFormVisible = false;
            // }
        },
  },

  watch: {

  },
  computed: {

  },
  filters: {

  }
 }
</script>

<style scoped lang='less'>
 
*{
  margin: 0;
  padding: 0;
}
.bigDIv{
  width: 100%;
  .qrcodeImg{
    position: relative;
.qrcodes{
  position: absolute;
  top: 19%;
  left: 46.3%;
  width: 65px;
  height: 65px;
  object-fit: contain;
}
.qrTime{
   position: absolute;
  top: 52.5%;
  left: 26.8%;
}
  }
  img{
    margin-top: -2px;
    width: 100%;
    object-fit: contain;
  }
  .last{
    margin-bottom: 25px;
  }
  .btn{
 position: relative;
    img{
         position: fixed;
    bottom: 0;
    left: 0;
    }
    .btnqrcodes{
             position: fixed;

      width: 22px;
      height: 22px;
      left: 50%;
      bottom: 2.2px;
    }
    .sjx{
      width: 8px;
      height: 8px;
      object-fit: contain;
      position: fixed;
      left: 56.5%;
      bottom: 5%;
      
    }
  }
    .colon {
    display: inline-block;
    margin: 0 1px;
    color: #ee0a24;
    font-size: 10px;
  }
  .block {
    display: inline-block;
    width: 14px;
    color: #fedf6d;
    font-size: 10px;
    text-align: center;
    background-color: #b31515;
  }
     .textTitle {
        margin: 0 auto;
        font-weight: 700;
        font-size: 8px;
    }
    .texts {
        margin: 9px auto;
        font-weight: 700;
        height: 12px;
        line-height: 12px;
        background-color: #f8f9fb;
        text-align: center;
        border-radius: 12px;
        font-size: 6px;
    }
    .texts.active {
        /* 被点击时的样式 */
        background-color: #e7eeff;
        border: #b9cdfe 1px solid;
        border-radius: 12px;
    }
        .codes{
        width: 70px;
        margin: 0 auto;
    }
    .qrCodeCss{
        width: 90px;
        height: 80px;
           background-image: url(https://oss.hshwhkj.com/images/qrCodeBgc.png);
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 auto;
        margin-top: -6px;
        margin-left: -1.2px;
        position: relative;
        .imgsas{
            width: 51.5px;
            height: 52px;
            position: absolute;
            top: 16px;
            left: 9.2px;
        }
    }
 
}
</style>